import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from '@elenasy/common-ui';

export function AuthGuard() {
  return () => {
    const router = inject(Router);
    const storageService = inject(StorageService);

    const token = storageService.token;

    if (token && token.length) {
      return true;
    }

    router.navigate(['/']);
    return false;
  };
}
