import { Route } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { DashboardHttpService } from './services/dashboard-http.service';
import { importProvidersFrom } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { DashboardHttpInterceptor } from './interceptors/dashboard-http.interceptor';
import { TitleCasePipe } from '@angular/common';
import { environment } from '../environments/environment';

export const appRoutes: Route[] = [
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.routes').then((m) => m.AUTH_ROUTES),
    providers: [
      {
        provide: 'API_URL',
        useValue: `${environment.apiUrl}`,
      },
    ],
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./dashboard/dashboard.routes').then((m) => m.DASHBOARD_ROUTES),
    providers: [
      importProvidersFrom(HttpClientModule),
      {
        provide: 'API_URL',
        useValue: environment.apiUrl,
      },
      DashboardHttpService,
      {
        provide: HTTP_INTERCEPTORS,
        useClass: DashboardHttpInterceptor,
        multi: true,
      },
      TitleCasePipe,
    ],
    canActivate: [AuthGuard()],
  },
  { path: '', redirectTo: 'auth/login', pathMatch: 'full' },
  { path: '**', redirectTo: 'auth', pathMatch: 'full' },
];
