import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoadingBarService {
  loading = false;
  loading$ = new BehaviorSubject(this.loading);

  start() {
    this.loading$.next(true);
  }

  stop() {
    this.loading$.next(false);
  }
}
