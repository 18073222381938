import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ICourseCategory } from '../interfaces/course-category';
import { IRole } from '../interfaces/role';
import { map } from 'rxjs';
import { TitleCasePipe } from '@angular/common';
import { DashboardAnalytics } from '../interfaces/dashboard-analytics';
import { IStudent, IStudentData } from '../interfaces/student';
import { IMeta } from '../interfaces/meta';
import { IAdminUser } from '../interfaces/admin-user';
import { ICourse, ICourseModuleQuiz, INotification } from '@elenasy/common-ui';
import { CourseAnalyticRating } from '../interfaces/course-analytics-rating.interface';
import { CourseAnalyticComment } from '../interfaces/course-analytics-comment.interface';
import { ICohort } from '../interfaces/cohort';

@Injectable()
export class DashboardHttpService {
  constructor(
    @Inject('API_URL') private baseUrl: string,
    private http: HttpClient,
    private titleCase: TitleCasePipe
  ) {}

  /********** Dashboard start **********/
  dashboardAnalytics(dateRange: string) {
    return this.http.get<DashboardAnalytics>(
      `${this.baseUrl}/analytics/dashboard?dateRange=${dateRange}`
    );
  }
  /********** Dashboard end **********/

  /********** Courses start **********/
  getCourses() {
    return this.http.get<{ data: ICourse[]; meta: IMeta }>(
      `${this.baseUrl}/course?order=DESC&pageSize=50`
    );
  }

  searchCourses(name: string) {
    return this.http
      .get<{ data: ICourse[]; meta: IMeta }>(
        `${this.baseUrl}/course?name=${name}`
      )
      .pipe(
        map(({ data }) => {
          return data.map((course) => ({
            id: course.id,
            name: course.name,
          }));
        })
      );
  }

  getCourseCategories() {
    return this.http.get<ICourseCategory[]>(
      `${this.baseUrl}/course/categories/all`
    );
  }

  createCourse(data: object) {
    return this.http.post(`${this.baseUrl}/course`, data);
  }

  getOneCourse(id: string) {
    return this.http.get<ICourse>(`${this.baseUrl}/course/${id}`);
  }

  deleteCourse(id: string) {
    return this.http.delete(`${this.baseUrl}/course/${id}`);
  }

  getCourseQuizzes(id: string) {
    return this.http.get<ICourseModuleQuiz[]>(
      `${this.baseUrl}/quiz/${id}/lesson`
    );
  }

  updateCourseDescription(id: string, data: object) {
    return this.http.patch(`${this.baseUrl}/course/${id}`, data);
  }

  updateCourseModule(id: string, data: object) {
    return this.http.patch(`${this.baseUrl}/course/${id}/add-lesson`, data);
  }

  updateCourseQuizzes(id: string, data: object) {
    return this.http.post(`${this.baseUrl}/lesson/bulk-lesson-update`, data);
  }

  updateCourseResources(id: string, data: object) {
    return this.http.patch(`${this.baseUrl}/${id}`, data);
  }

  deleteCourseModule(id: string) {
    return this.http.delete(`${this.baseUrl}/lesson/${id}`);
  }

  getLessonStats(lessonId: string) {
    return this.http.get<{
      data: { passRate: null };
    }>(`${this.baseUrl}/analytics/lesson/${lessonId}/stats`);
  }
  /********** Courses end **********/

  /********** Cohorts start **********/
  getCohorts() {
    return this.http.get<{ data: ICohort[] }>(
      `${this.baseUrl}/cohort?pageSize=50`
    );
  }

  getOneCohort(id: string) {
    return this.http.get<ICohort>(`${this.baseUrl}/cohort/${id}`);
  }

  createCohort(data: object) {
    return this.http.post(`${this.baseUrl}/cohort`, data);
  }

  updateCohort(id: string, data: object) {
    return this.http.patch<ICohort>(`${this.baseUrl}/cohort/${id}`, data);
  }

  assignCohortCourse(id: string, data: object) {
    return this.http.patch(`${this.baseUrl}/cohort/${id}/assign-courses`, data);
  }

  removeCohortCourse(id: string, data: object) {
    return this.http.patch(`${this.baseUrl}/cohort/${id}/remove-courses`, data);
  }
  /********** Cohorts end **********/

  /********** Students start **********/
  getAllStudents() {
    return this.http.get<IStudentData>(
      `${this.baseUrl}/user?order=DESC&pageSize=50`
    );
  }

  getOneStudent(id: string) {
    return this.http.get<IStudent>(`${this.baseUrl}/user/${id}`);
  }

  searchUser(name: string) {
    return this.http
      .get<IStudentData>(`${this.baseUrl}/user?name=${name}`)
      .pipe(
        map(({ data }) => {
          return data.map((d) => ({
            id: d.id,
            name: `${d.firstName} ${d.lastName}`,
          }));
        })
      );
  }

  createStudent(data: object) {
    return this.http.post(`${this.baseUrl}/admin/student/add`, data);
  }

  updateStudent(data: object, id: string) {
    return this.http.patch(`${this.baseUrl}/user/${id}`, data);
  }

  deleteStudent(id: string) {
    return this.http.delete(`${this.baseUrl}/user/${id}`);
  }
  /********** Students end **********/

  /********** Settings and Staff start **********/
  getRoles() {
    return this.http.get<IRole[]>(`${this.baseUrl}/role`).pipe(
      map((role) => {
        const roles: IRole[] = [];

        role.forEach((r) => {
          roles.push({ ...r, name: this.titleCase.transform(r.name) });
        });

        return roles;
      })
    );
  }

  createStaff(data: object) {
    return this.http.post(`${this.baseUrl}/admin`, data);
  }

  getAdmins() {
    return this.http.get<{ data: IAdminUser[]; meta: IMeta }>(
      `${this.baseUrl}/admin?order=DESC`
    );
  }

  getOneAdmin(id: string) {
    return this.http.get<IAdminUser>(`${this.baseUrl}/admin/${id}`);
  }

  getCurrentAdminInfo(email: string) {
    return this.http.get(`${this.baseUrl}/email/${email}`);
  }

  updateStaff(id: string, data: object) {
    return this.http.patch(`${this.baseUrl}/admin/${id}`, data);
  }

  updateAdminProfile(id: string, data: object) {
    return this.http.patch(`${this.baseUrl}/admin/${id}`, data);
  }

  deleteAdmin(id: string) {
    return this.http.delete(`${this.baseUrl}/admin/${id}`);
  }
  /********** Settings and Staff start **********/

  /********** Notifications start **********/
  notifyStudent(data: object) {
    return this.http.post(`${this.baseUrl}/notification/notfiy-student`, data);
  }

  getAllNotifications() {
    return this.http.get<INotification[]>(`${this.baseUrl}/notification`);
  }

  deleteNotification(id: string) {
    return this.http.delete(`${this.baseUrl}/notification/${id}`);
  }
  /********** Notifications end **********/

  /********** Analytics start **********/
  getCourseAnalytics(courseId: string, query: string) {
    return this.http.get<{
      data: CourseAnalyticRating | CourseAnalyticComment[];
    }>(
      `${this.baseUrl}/analytics/course/stats?courseId=${courseId}&query=${query}`
    );
  }
  /********** Analytics end **********/

  /********** General start **********/
  uploadFiles(file: object) {
    return this.http.post<{ data: { url: string; cdnUrl: string }[] }>(
      `${this.baseUrl}/file-upload`,
      file,
      {
        reportProgress: true,
        observe: 'events',
      }
    );
  }
}
