import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class StorageService {
  url = '';

  setItem(key: string, value: any) {
    if (typeof value === 'string') {
      localStorage.setItem(key, value);
    } else {
      const stringValue = JSON.stringify(value);
      localStorage.setItem(key, stringValue);
    }
  }

  getItem(key: string) {
    const value = localStorage.getItem(key);
    return value ? JSON.parse(value) : null;
  }

  clearItem(key: string) {
    localStorage.removeItem(key);
  }

  get token() {
    return localStorage.getItem('elenasy-admin-access-token');
  }

  get studentToken() {
    return localStorage.getItem('elenasy-student-access-token');
  }

  clearToken() {
    return localStorage.removeItem('elenasy-admin-access-token');
  }

  clearStudentToken() {
    return localStorage.removeItem('elenasy-student-access-token');
  }

  get adminUser() {
    return this.getItem('elenasy-admin-user');
  }

  get user() {
    return this.getItem('elenasy-student');
  }
}
