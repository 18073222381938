import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ModalService {
  visible$ = new BehaviorSubject<boolean>(false);
  isPublished = true;
  isPublished$ = new BehaviorSubject<boolean>(this.isPublished);

  private modalState: ModalOptions = {
    headerText: '',
    contentHeadingText: '',
    contentBodyText: '',
    cancelButtonLabelText: '',
    acceptButtonLabelText: '',
    loading: false,
    // visible: false,
    status: 'confirm',
    password: '',
    addToDraft: true,
  };
  modalState$ = new BehaviorSubject<ModalOptions>(this.modalState);

  modalConfig(options: ModalOptions) {
    this.modalState = options;
    this.modalState$.next(options);
  }

  showModal() {
    this.visible$.next(true);
  }

  hideModal() {
    this.visible$.next(false);
  }

  startLoading() {
    this.modalState$.next({ ...this.modalState, loading: true });
  }
}
export type ModalStatus = 'success' | 'confirm' | 'error' | 'delete';

export interface ModalOptions {
  headerText?: string;
  contentHeadingText?: string;
  contentBodyText?: string;
  cancelButtonLabelText?: string;
  acceptButtonLabelText?: string;
  loading?: boolean;
  visible?: boolean;
  status: ModalStatus;
  position?: Positions;
  showPassword?: boolean;
  password?: string;
  addToDraft?: boolean;
  showAddToDraft?: boolean;
  onAction?: () => void;
  onDismiss?: () => void;
}

type Positions =
  | 'center'
  | 'top'
  | 'bottom'
  | 'left'
  | 'right'
  | 'topleft'
  | 'topright'
  | 'bottomleft'
  | 'bottomright';
