import { Injectable, inject } from '@angular/core';
import {
  HttpInterceptor,
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { EMPTY, Observable, catchError } from 'rxjs';
import { ModalService, StorageService } from '@elenasy/common-ui';
import { Router } from '@angular/router';

@Injectable()
export class DashboardHttpInterceptor implements HttpInterceptor {
  storageService = inject(StorageService);
  modalService = inject(ModalService);
  router = inject(Router);

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const token = this.storageService.token;

    if (req.url.includes('file-upload')) {
      req = req.clone({
        headers: req.headers.append('Authorization', `Bearer ${token}`),
      });
    } else {
      req = req.clone({
        headers: req.headers
          .append('Content-Type', 'application/json')
          .append('Authorization', `Bearer ${token}`),
      });
    }

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        this.modalService.showModal();

        switch (error.status) {
          case 400:
            this.storageService.url = this.router.url;
            this.modalService.modalConfig({
              status: 'error',
              contentHeadingText: `${error.status}: Bad request`,
              contentBodyText: error.error.message,
            });
            return EMPTY;
          case 401:
            this.storageService.url = this.router.url;
            this.modalService.modalConfig({
              status: 'error',
              contentHeadingText: `${error.status}: Unauthorized`,
              contentBodyText: error.error.message,
              onDismiss: () => this.router.navigate(['/']),
            });
            return EMPTY;
          case 403:
            this.storageService.url = this.router.url;
            this.modalService.modalConfig({
              status: 'error',
              contentHeadingText: `${error.status}: Unauthorized`,
              contentBodyText:
                error.error.message +
                '. You do not have the required permissions to access this route.',
              onDismiss: () =>
                this.router.navigate(['/dashboard/admin/courses']),
            });
            return EMPTY;
          case 404:
            this.modalService.modalConfig({
              status: 'error',
              contentHeadingText: `${error.status}: ${error.error.error}`,
              contentBodyText: error.error.message,
            });
            throw error;
          case 422:
            throw error;
          case 500:
          case 504:
            if (req.url.includes('file-upload')) {
              this.modalService.hideModal();
              throw error;
            }
            this.modalService.modalConfig({
              status: 'error',
              contentHeadingText: error.error.message,
              contentBodyText:
                'The server is unable to fulfill this request at this time. Please try again',
            });
            throw error;
          default:
            if (req.url.includes('file-upload')) {
              this.modalService.hideModal();
              throw error;
            }
            this.modalService.modalConfig({
              status: 'error',
              contentHeadingText: error.statusText,
              contentBodyText:
                'This error may be due to poor or no internet connection. Please check your connection and try again',
            });
            return EMPTY;
        }
      })
    );
  }
}
