import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ResolveStart, Router, RouterModule } from '@angular/router';
import { ProgressBarModule } from 'primeng/progressbar';
import { LoadingBarService } from './services/loading-bar.service';

@Component({
  standalone: true,
  imports: [CommonModule, RouterModule, ProgressBarModule],
  selector: 'ele-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'admin';
  loading = false;

  constructor(private lbService: LoadingBarService) {}

  ngOnInit(): void {
    this.lbService.loading$.subscribe({
      next: (val) => (this.loading = val),
    });
  }
}
